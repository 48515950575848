import { useEffect } from "react";
import amplitude from 'amplitude-js';

import SquareCard from "../../UI/squareCard/SquareCard";
import TextCard from "../../UI/textCard/TextCard";

import styles from "./MainGoal.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const MainGoal = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.mainGoal
    const themeData = data.themeData

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_main_goal_appeared');
    }, [])

    const selectCard = card => {
        if (card.id === 2 && !pageInfo.squareCards) {
            localStorage.setItem('goal', 'Feel amazing');
        } else {
            localStorage.setItem('goal', strings[card.section][card.page].labels[card.localID]["text"]);
        }
        amplitude.getInstance().logEvent('button_onboarding_main_goal_tapped', {answer: card.title});
        const answer = new Map();
        answer.set("key", "main_goal");
        answer.set("value", card.title);
        nextStep(answer);
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>What is your main goal?</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>Our plan adapts to all types of goals.</span>
            { pageInfo.squareCards ? (
                <div className={`${styles.cardsContainer} flex-row`}>
                    { pageInfo.cards?.map(card => (
                        <div key={card.id} className={styles.cardContainer}>
                            <SquareCard card={card} selectCard={selectCard}/>
                        </div>
                    )) }
                </div>
            ) : (
                <>
                    { pageInfo.cards?.map(card => (
                        <div key={card.id} className={styles.cardContainer}>
                            <TextCard
                                backgroundColor={themeData.cardBackgroundColor}
                                isBigImage={pageInfo.bigImageCards}
                                card={card}
                                selectCard={selectCard}
                                borderColor={themeData.checkboxCardColor}
                            />
                        </div>
                    )) }
                </>
            )}
        </div>
    )
}   

export default MainGoal;