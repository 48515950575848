import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import useJsonParse from "../../../hooks/useJsonParse";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import SwiperComponent from "../../UI/swiperComponent/SwiperComponent";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./CurrentBody.module.scss";

const CurrentBody = props => {
    const { nextStep, campaignData } = props;
    const pageInfo = campaignData.settings.goalCurrentBody;
    const bodyImages = useJsonParse(pageInfo.cards);
    const [currentSlide, setCurrentSlide] = useState(0);
    const themeData = campaignData.themeData

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_current_body_appeared');
    }, []);

    const changeSlide = value => {
        setCurrentSlide(value)
    }

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_current_body_tapped', {answer: currentSlide + 1});
        const answer = new Map();
        answer.set("key", "current_body");
        answer.set("value", currentSlide + 1);
        nextStep(answer);
    }

    return (
        <>
            <div className={`${styles.mainContainer} flex-column`}>
                <h1 className={styles.cardTitle}>
                    { pageInfo.multicolorHeader ? (
                        <>
                            { strings.goal.currentBody.multicolorHeader.titleOne }
                            <span>
                                { strings.goal.currentBody.multicolorHeader.titleTwo }
                            </span>
                            { strings.goal.currentBody.multicolorHeader.titleThree }
                        </>
                    ) : (
                        <>{ strings.goal.currentBody.title }</>
                    )}
                </h1>
                <div className={styles.bodySlider}>
                    <SwiperComponent data={bodyImages} withBorder={pageInfo.withBorder} defaultSlide={0} currentSlide={currentSlide} sliderTitle={'Current'} changeSlide={changeSlide}/>
                </div>
                <div className={`${styles.sliderLabels} flex-between`}>
                    <span>{strings.goal.currentBody.plump}</span>
                    <span>{strings.goal.currentBody.skinny}</span>
                </div>
                <ContinueButton
                    nextStep={clickContinue}
                    theme={themeData.continueButton}
                />
            </div>
        </>
    )
}   

export default CurrentBody;