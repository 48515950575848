import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import heardAboutData from "../../../core/static/plan/heardAbout.json";
import TextCard from "../../UI/textCard/TextCard";

import styles from "./HeardAbout.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const HeardAbout = (props) => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const textInfo = strings.plan.heardAbout

    const [cards, setCards] = useState(heardAboutData);
    const [activeCard, setActiveCard] = useState();

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_heard_about_appeared');
    }, [])

    const selectCard = (card) => {
        setActiveCard(card);
        amplitude.getInstance().logEvent('screen_onboarding_heard_about_tapped', {answer: card.title});
        const answer = new Map();
        answer.set("key", "heard_about");
        answer.set("value", card.title);
        nextStep(answer)
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>{textInfo.title}</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>{textInfo.subtitle}</span>
            { cards?.map(card => (
                <div key={card.id} className={styles.cardContainer}>
                    <TextCard
                        card={card}
                        cardHeight={75}
                        selectCard={selectCard}
                        activeCard={activeCard}
                        borderColor={themeData.checkboxCardColor}
                    />
                </div> ))
            }
        </div>
    )
}   

export default HeardAbout;