
import styles from "./SquareCard.module.scss";
import LazyImage from "../lazyImage/LazyImage";

const SquareCard = props => {
    const { card, cardHeight, selectCard, activeCard } = props;

    return (
        <>
            <div className={`${styles.cardContainer} ${activeCard?.id === card.id  ? styles.activeCard : ''} flex-column cardContainer`}
                 style={{height: cardHeight ? `${cardHeight}px` : ''}}
                 onClick={() => selectCard(card)}
            >
                <LazyImage
                    className={styles.cardIcon}
                    srcWebp={card.iconWebp}
                    src={card.icon}
                    alt=""
                />
                <p className={`${styles.cardTitle} cardTitle`} style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}>{card.title}</p>
            </div>
            {
                card.message && activeCard?.id === card.id ? (
                    <p className={styles.cardMessage}>{card.message}</p>
                ) : null
            }
        </>
    )
}   

export default SquareCard;