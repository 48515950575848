import styles from "./LazyImage.module.scss";

const LazyImage = (props) => {
    const { className, srcWebp, notLazy, src, alt, style, onClick } = props

    return (
        <picture className={className} style={style}>
            <source srcSet={srcWebp} type="image/webp" />
            <source srcSet={src} type="image/jpeg" />
            {notLazy ? (
                <img src={src} alt={alt} onClick={onClick} />
            ) : (
                <img src={src} alt={alt} loading="lazy" onClick={onClick} />
                )
            }
        </picture>
    );
};

export default LazyImage;