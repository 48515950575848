import { useEffect } from "react";
import amplitude from 'amplitude-js';
import strings from "../../../assets/localization/strings.jsx";
import styles from "./Breakfast.module.scss";
import breakfastData from "../../../core/static/nutrition/breakfast.json";
import ButtonCard from "../../UI/buttonCard/ButtonCard";
import LazyImage from "../../UI/lazyImage/LazyImage";


const cardStyles = {
    divStyles: {
        width: 'calc(50% - 8px)',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        padding: '10px 15px'
    },
    pStyles: {
        fontWeight: 400,
        lineHeight: '18px',
        padding: 0
    }
}

const Breakfast = props => {
    const { nextStep } = props;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_breakfast_appeared');
    }, [])

    const selectCard = card => {
        amplitude.getInstance().logEvent('button_onboarding_breakfast_tapped', {answer: card.title});
        const answer = new Map();
        answer.set("key", "breakfast");
        answer.set("value", card.title);
        nextStep(answer);
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>{strings.nutrition.breakfast.title}</h1>
            <div className={`${styles.imageContainer}`}>
                <LazyImage
                    className={styles.image}
                    srcWebp="/images/nutrition/breakfast.webp"
                    src="/images/nutrition/breakfast.png"
                    alt=""
                />
            </div>
            <div className={`${styles.cardsContainer}`}>
                { breakfastData?.map(card => (
                    <ButtonCard key={card.id} card={card} selectCard={selectCard} cardStyles={cardStyles} />
                  ))
                }
            </div>
        </div>
    )
}

export default Breakfast;