import { useEffect } from "react";
import amplitude from 'amplitude-js';

import styles from "./ChairWorkout.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import LazyImage from "../../UI/lazyImage/LazyImage";

const ChairWorkout = (props) => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const textInfo = strings.plan.chairWorkout
    const ageInfo = localStorage.getItem('ageInfo');

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_chair_workouts_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>{textInfo.title}</h1>

            <div className={styles.commentArea} >
                <div className={styles.absoluteImageBox} >
                    <LazyImage
                        srcWebp="/images/plan/comment-image.webp"
                        src="/images/plan/comment-image.png"
                        alt=""
                    />
                </div>
                <div className={styles.commentBox} >
                    <p className={styles.comment} >
                        {textInfo.comment.text}
                    </p>
                    <div className={`${styles.subtextBox} flex-row`} >
                        <div className={styles.subtextImage}>
                            <LazyImage
                                srcWebp="/images/plan/subtext-icon.webp"
                                src="/images/plan/subtext-icon.png"
                                alt=""
                            />
                        </div>
                        <p className={`${styles.subtext} flex-row`} >
                            {textInfo.comment.subtext}
                            <span>{ageInfo}</span>
                            {textInfo.comment.last}
                        </p>
                    </div>
                </div>
            </div>

            <ContinueButton
                text={strings.continue}
                nextStep={clickContinue}
                theme={themeData.continueButton}
            />
        </div>
    )
}   

export default ChairWorkout;