import styles from "./FailedRegistrationPopup.module.scss";
import LazyImage from "../lazyImage/LazyImage";

const FailedRegistrationPopup = props => {
    const { onSubmit } = props;

    return (
        <div className={styles.popupWindow}>
            <div className={styles.popupContainer}>
                <div className={styles.imageContainer}>
                    <LazyImage
                        srcWebp="/images/result/Attention.webp"
                        src="/images/result/Attention.png"
                        alt=""
                    />
                </div>
                <p> Something went wrong <br /> Please try again</p>
                <button onClick={onSubmit} className={styles.popupButton}>Try again</button>
            </div>
        </div>
    )
}   

export default FailedRegistrationPopup;