import styles from "./GetButton.module.scss";

const GetButton = props => {
    const { nextPage, small, text = 'Get my program', style } = props;
    const buttonClass = `${styles.buttonContainer} ${styles[`${style}Campaign`]} ${small ? styles.smallButton : ''}`

    return (
        <button className={buttonClass} onClick={nextPage}>
            <span className={styles.buttonText}>{text}</span>
            <span className={styles.pulseButtonRings}></span>
            <span className={styles.pulseButtonRings}></span>
            <span className={styles.pulseButtonRings}></span>
        </button>
    )
}   

export default GetButton;