import styles from "./RefundBox.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";

const RefundBox = (props) => {
    const { style } = props;

    return (
        <>
            { style === 'lightGreen' ? (
                <div className={`${styles.notifyBox} flex-row`}>
                    <div className={styles.notifyImageBox} >
                        <LazyImage
                            className={styles.notifyImage}
                            srcWebp="/images/paywall/greenTheme/ok.webp"
                            src="/images/paywall/greenTheme/ok.png"
                            alt=""
                        />
                    </div>
                    <div className={`${styles.infoBox} flex-column`} >
                        {strings.paywall.greenTheme.cards.notifyBox.mainText}
                        <span>{strings.paywall.greenTheme.cards.notifyBox.subText}</span>
                    </div>
                </div>
            ): (
                <>
                    <div className={styles.refundBox}>
                        <div className={styles.refundBoxContainer}>
                            <div className={styles.refundBoxContent}>
                                <div className={styles.cupImage}>
                                    <img loading="lazy" src="/images/paywall/icons/goal-result.svg" alt="" />
                                </div>
                                <div className={styles.refundBoxTitle}>
                                    <p>
                                        {strings.paywall.refund.value}
                                        <br />
                                        <span className={styles.boldText}>{strings.paywall.refund.rate}</span>
                                        <br />
                                        {strings.paywall.refund.year}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className={styles.refundBoxText}>{strings.paywall.refund.days}<span className={styles.redText}>{strings.paywall.refund.mbg}</span></p>
                </>
            )}
        </>
      )
  }

export default RefundBox;