import styles from "./ImageSaleAd.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";

const ImageSaleAd = ({ campaign, pageInfo, style }) => {
    const containerStyle = style === 'bf'
                           ? styles.bFContainer
                           : style === 'ny'
                             ? styles.nYContainer
                             : ""
    let saleImgPath = campaign.includes('chl')
                      ? "/images/paywall/a/paywall-a_chl.png"
                      : "/images/paywall/a/paywall-a.gif"
    let saleImgPathWebp = campaign.includes('chl')
        ? "/images/paywall/a/paywall-a_chl.webp"
        : "/images/paywall/a/paywall-a.gif"
    return (
        <div className={`${styles.galleryImageSale} ${containerStyle}`}>
            <div className={`${styles.imageSaleAd} ${styles.imageSaleAdTop} flex-row`}>
                <div className={`${styles.imageSaleAdText} flex-row`}>
                    <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                    <LazyImage
                        className={styles.imageSaleStar}
                        srcWebp={pageInfo.saleAdImageWebp}
                        src={pageInfo.saleAdImage}
                        alt=""
                    />
                    { pageInfo.saleID !== 'trial' && (<span style={{"fontSize": strings.paywall.cardHeader.sale.fontSize}}>{strings.paywall.cardHeader.sale.text}</span>) }
                    { pageInfo.saleID !== 'trial' && (
                        <LazyImage
                            className={styles.imageSaleStar}
                            srcWebp={pageInfo.saleAdImageWebp}
                            src={pageInfo.saleAdImage}
                            alt=""
                        />
                    )}

                    <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                    <LazyImage
                        className={styles.imageSaleStar}
                        srcWebp={pageInfo.saleAdImageWebp}
                        src={pageInfo.saleAdImage}
                        alt=""
                    />
                   { pageInfo.saleID !== 'trial' && (<span style={{"fontSize": strings.paywall.cardHeader.sale.fontSize}}>{strings.paywall.cardHeader.sale.text}</span>)}
                   { pageInfo.saleID !== 'trial' && (
                       <LazyImage
                           className={styles.imageSaleStar}
                           srcWebp={pageInfo.saleAdImageWebp}
                           src={pageInfo.saleAdImage}
                           alt=""
                       />
                   )}

                    <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                </div>
            </div>
            <LazyImage
                className={styles.galleryImage}
                srcWebp={saleImgPathWebp}
                src={saleImgPath}
                alt=""
            />
            <div className={`${styles.imageSaleAd} ${styles.imageSaleAdBottom} flex-row`}>
                <div className={`${styles.imageSaleAdText} flex-row`}>
                  <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                    <LazyImage
                        className={styles.imageSaleStar}
                        srcWebp={pageInfo.saleAdImageWebp}
                        src={pageInfo.saleAdImage}
                        alt=""
                    />
                 { pageInfo.saleID !== 'trial' && (<span style={{"fontSize": strings.paywall.cardHeader.sale.fontSize}}>{strings.paywall.cardHeader.sale.text}</span>)}
                 { pageInfo.saleID !== 'trial' && (
                     <LazyImage
                         className={styles.imageSaleStar}
                         srcWebp={pageInfo.saleAdImageWebp}
                         src={pageInfo.saleAdImage}
                         alt=""
                     />
                 )}

                  <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                    <LazyImage
                        className={styles.imageSaleStar}
                        srcWebp={pageInfo.saleAdImageWebp}
                        src={pageInfo.saleAdImage}
                        alt=""
                    />

                 { pageInfo.saleID !== 'trial' && (<span style={{"fontSize": strings.paywall.cardHeader.sale.fontSize}}>{strings.paywall.cardHeader.sale.text}</span>)}
                 { pageInfo.saleID !== 'trial' && (
                     <LazyImage
                         className={styles.imageSaleStar}
                         srcWebp={pageInfo.saleAdImageWebp}
                         src={pageInfo.saleAdImage}
                         alt=""
                     />
                 )}
                  <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                </div>
            </div>
        </div>
    )
}

export default ImageSaleAd;