import styles from "./SuccessRegistrationPopup.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const SuccessRegistrationPopup = () => {
    return (
        <div className={styles.popupWindow}>
            <div className={styles.popupContainer}>
                <div className={styles.loadingRing}>
                <img src={"/images/success.png"} alt={strings.success} />
                </div>
                <p>{strings.success}</p>
            </div>
        </div>
    )
}   

export default SuccessRegistrationPopup;