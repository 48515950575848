import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./LifestyleResult.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../../UI/lazyImage/LazyImage";

const LifestyleResult = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.lifestyleResult
    const textInfo = strings.lifestyle.result

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_section_lifestyle_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_lifestyle_tapped');
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <ResultHeader
                url={'/images/result/lifestyle-result.jpg'}
                urlWebp={'/images/result/lifestyle-result.webp'}
                theme={themeData?.menuProgressBackground}
            />
            {
                pageInfo.asComment ? (
                    <>
                        <h1 className={styles.commentTitle}>{textInfo.title}</h1>
                        <div className={`${styles.comment} flex-row`}>
                            <div className={styles.commentImageBox}>
                                <LazyImage
                                    className={styles.commentImage}
                                    srcWebp={pageInfo.resultImageWebp}
                                    src={pageInfo.resultImage}
                                    alt=""
                                />
                            </div>
                            <div className={`${styles.commentArea} flex-column`}>
                                <img loading="lazy" src="/images/stars.svg" alt="" />
                                <div className={styles.commentBox}>
                                    { pageInfo.cfComment ? (
                                            <>
                                                <p className={styles.commentText}>
                                                    { textInfo.cfCommentFirst }
                                                </p>
                                                <p className={styles.commentText}>
                                                    { textInfo.cfCommentLast }
                                                </p>
                                            </>
                                        ) : (
                                            <p className={styles.commentText}>
                                                { textInfo.comment }
                                            </p>
                                        )
                                    }
                                </div>
                                <p className={styles.commentAuthor}>
                                    {textInfo.commentAuthor}
                                </p>
                            </div>
                        </div>
                        <span className={styles.commentHint}>
                            {textInfo.commentHint.start}
                             <span className={styles.hintBold}>{textInfo.commentHint.middle}</span>
                            {textInfo.commentHint.end}
                        </span>
                        <ContinueButton
                            text={strings.continue}
                            nextStep={clickContinue}
                            theme={themeData.continueButton}
                        />
                    </>
                ) : (
                    <>
                        <h1 className={styles.title}>Everdance was made for people just like you!</h1>
                        <LazyImage
                            className={styles.image}
                            srcWebp="/images/result/lifestyle-image.webp"
                            src="/images/result/lifestyle-image.jpg"
                            alt=""
                        />
                        <p className={`${styles.text} resultSubtitle`}><span>83%</span> of Everdance users</p>
                        <span className={`${styles.subtitle} componentSubtitle`}>claim that the dance plan we offer is easy to follow and makes it simple to stay on track.</span>
                        <ContinueButton
                            text={'All right'}
                            nextStep={clickContinue}
                            theme={themeData.continueButton}
                        />
                    </>
                )
            }

        </div>
    )
}   

export default LifestyleResult;