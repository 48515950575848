import styles from "./ResultHeader.module.scss";
import LazyImage from "../lazyImage/LazyImage";

const ResultHeader = props => {
    const { url, urlWebp, theme } = props;

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <div className={`${styles.circleContainer} ${styles[`${theme}CircleContainer`]}`}>
                <LazyImage
                    src={url}
                    srcWebp={urlWebp}
                    alt=""
                />
            </div>
        </div>
    )
}   

export default ResultHeader;