import styles from "./CoverflowSwiper.module.scss";
import {useEffect, useRef} from "react";
import { register } from 'swiper/element/bundle';
import LazyImage from "../lazyImage/LazyImage";

const coverflowParams = { rotate: 0, depth: 185, modifier: 1, slideShadows: false}
const swiperParams = {
    effect: 'coverflow',
    speed: 500,
    coverflowEffect: {
        stretch: 196,
        ...coverflowParams
    },
    centeredSlides: true,
    slidesPerView: '1.1',
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    pagination: {
        dynamicBullets: true,
        dynamicMainBullets: 1,
    },
    breakpoints: {
        375: {coverflowEffect: {stretch: 196, ...coverflowParams}},
        378: {coverflowEffect: {stretch: 197, ...coverflowParams}},
        381: {coverflowEffect: {stretch: 198, ...coverflowParams}},
        384: {coverflowEffect: {stretch: 199, ...coverflowParams}},
        387: {coverflowEffect: {stretch: 200, ...coverflowParams}},
        390: {coverflowEffect: {stretch: 201, ...coverflowParams}},
        393: {coverflowEffect: {stretch: 202, ...coverflowParams}},
        396: {coverflowEffect: {stretch: 203, ...coverflowParams}},
        399: {coverflowEffect: {stretch: 204, ...coverflowParams}},
        402: {coverflowEffect: {stretch: 205, ...coverflowParams}},
        405: {coverflowEffect: {stretch: 206, ...coverflowParams}},
        408: {coverflowEffect: {stretch: 207, ...coverflowParams}},
        411: {coverflowEffect: {stretch: 208, ...coverflowParams}},
        414: {coverflowEffect: {stretch: 209, ...coverflowParams}},
        417: {coverflowEffect: {stretch: 210, ...coverflowParams}},
        420: {coverflowEffect: {stretch: 211, ...coverflowParams}},
        423: {coverflowEffect: {stretch: 212, ...coverflowParams}},
        425: {coverflowEffect: {stretch: 213, ...coverflowParams}}
    },
}

const CoverflowSwiper = (props) => {
    const { changeSlide, data } = props
    const pageInfo = data.settings.coverflowSwiper;
    const swiperElRef = useRef(null);

    useEffect(() => {
        register();
        Object.assign(swiperElRef.current, swiperParams);
        swiperElRef.current.addEventListener('slidechangetransitionend', (e) => {
            const [swiper, progress] = e.detail;
            changeSlide ? changeSlide(swiper.previousRealIndex + 1) : null
        });
        swiperElRef.current.initialize()
    }, []);

    return (
        <swiper-container
            ref={swiperElRef}
            class={styles.greenSwiper}
            init="false"
        >
            {pageInfo.images.map((image, idx) => (
                <swiper-slide key={idx} class={styles.swiperSlide}>
                    <div className={styles.swiperCard}>
                        <LazyImage
                            className={styles.logoImage}
                            srcWebp={`/images/topOfDance/slide${image.key}.webp`}
                            src={`/images/topOfDance/slide${image.key}.png`}
                            alt=""
                        />
                    </div>
                </swiper-slide>
            ))}
        </swiper-container>
    )
}

export default CoverflowSwiper
