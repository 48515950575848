import { useState, useEffect } from "react";

import amplitude from 'amplitude-js';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import WaitingRegistrationPopup from "../../UI/waitingRegistrationPopup/WaitingRegistrationPopup";
import SuccessRegistrationPopup from "../../UI/successfulRegistrationPopup/SuccessRegistrationPopup";
import styles from "./RegistrationOffer.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../../UI/lazyImage/LazyImage";
const RegistrationOffer = props => {
    const { nextStep , liveMode} = props
    const textData = strings.registration.addOffer
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)
    const [displaySuccessRegistrationPopup, setDisplaySuccessRegistrationPopup] = useState(false)

    const paypalId = 'Af7bctxkCUaREhFJ0BemXCIe_wjt63BQZ6lAPH58TSx-ew4SMvdCAXT5vKgf0iiAPGmGGo9TGSnsRddE'
    const testPaypalId = 'AVMWUzb4KSB_QhCJ6MAB72V7RgeM4KVLPSDcqhDDi6HPfXLB70erhueUmevRgsY74_kXlO4_ucmI75pP'
    const [paypalClientId, setPaypalClientId] = useState( localStorage.getItem('liveMode') === "true" ? paypalId : testPaypalId);
    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_add_offer_appeared');
    }, [])

    const paypalSubscribe = (data, actions) => {
    setDisplayWaitingRegistrationPopup(true)
            //facebookCapi('AddPaymentInfo', `paymentinfo.${getCookie('id')}`, null, period?.paypal_card_id);
            return actions.subscription.create({
                plan_id: localStorage.getItem('liveMode') === "true" ? "P-78872118MU622581EM27TLGA" : "P-01W53846FU6608113M2QQS6A",
            });
        };

        const paypalOnApprove = async (data, details) => {
          setDisplayWaitingRegistrationPopup(false)
                          setDisplaySuccessRegistrationPopup(true)
                          setTimeout(() => {
                           setDisplaySuccessRegistrationPopup(false)
                           nextStep();
          }, 3000);
        }

    const confirmAction = async () => {
         setDisplayWaitingRegistrationPopup(true)
        amplitude.getInstance().logEvent('button_onboarding_add_to_my_plan_tapped');
        ////////
        if(localStorage.getItem('paymentMethodType') === "stripe"){

        let purchaseResult = await fetch("https://1fqev002he.execute-api.us-east-1.amazonaws.com/dev/buy_program", {
                   method: "POST",
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json'
                   },
                   body: JSON.stringify({
                       priceId: "price_1PoNzrJwa78Nc1FjiiqN0iGV",
                       customerId: localStorage.getItem('customer_id'),

                       profileId: localStorage.getItem('profile_id'),
                       programId: 'ed-1721802472811',

                       paymentMethodId: localStorage.getItem('paymentMethodId'),
                       email: localStorage.getItem('emailInfo'),
                       amplitude_user_id: amplitude.getInstance().options.userId,
                       location: localStorage.getItem('search'),
                       onboarding_id: localStorage.getItem('onboarding_id'),
                       livemode: localStorage.getItem('liveMode'),
                   }),
               }).then(async (response) => {
                   console.log("createSubscription result");
                   let resultBody = response.json();
                   await resultBody.then( async (json) => {
                       console.log(json)

                       return json
                   });
               });
                console.log("purchaseResult");
                console.log(purchaseResult);
                setDisplayWaitingRegistrationPopup(false)
                setDisplaySuccessRegistrationPopup(true)
                setTimeout(() => {
                 setDisplaySuccessRegistrationPopup(false)
                 nextStep();
                }, 3000);

          }

    }

    const skipStep = () => {
        amplitude.getInstance().logEvent('button_onboarding_skip_tapped');
        nextStep();
    }

    return (
    <>
             { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }
             { displaySuccessRegistrationPopup ? (<SuccessRegistrationPopup />) : null }

        <div className={`${styles.mainContainer} flex-column`}>

            <h1 className={styles.title}>{textData.title}</h1>
            <div className={`${styles.ceoBox} flex-column`}>
                <div className={styles.ceoImage}>
                    <LazyImage
                        className={styles.image}
                        srcWebp="/images/result/registration-offer.webp"
                        src="/images/result/registration-offer.png"
                        alt=""
                    />
                </div>
                <div className={`${styles.ceoBoxInfo} flex-column`}>
                    <div className={styles.ceoTitle}>
                        {textData.ceoTitle}
                    </div>
                    <div className={styles.ceoSubtitle}>
                        {textData.ceoSubtitle}
                    </div>
                    <div className={`${styles.ceoBonus} flex-row`}>
                        <div className={`${styles.ceoBonusColumn} flex-row`}>
                            <div className={styles.ceoBonusImage}>
                                <LazyImage
                                    className={styles.image}
                                    srcWebp="/images/icons/fire-3x.webp"
                                    src="/images/icons/fire-3x.png"
                                    alt=""
                                />
                            </div>
                            <div className={styles.ceoBonusInfo}>
                                {textData.ceoWorkouts}
                            </div>
                        </div>
                        <div className={`${styles.ceoBonusColumn} flex-row`}>
                            <div className={styles.ceoBonusImage}>
                                <LazyImage
                                    className={styles.image}
                                    srcWebp="/images/gift_icon.webp"
                                    src="/images/gift_icon.png"
                                    alt=""
                                />
                            </div>
                            <div className={styles.ceoBonusInfo}>
                                {textData.ceoWarmups}
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.ceoPrice} flex-between`}>
                        <div className={`${styles.ceoPriceContainer} flex-column`}>
                            <h3 className={styles.ceoPriceTitle}>{textData.ceoAccess}</h3>
                            <div className={`flex-row`}>
                                <p className={styles.oldPrice}>$31.99 <span></span></p>
                                <div className={styles.ceoDiscount}>{textData.ceoDiscount}</div>
                            </div>
                        </div>
                        <div className={`${styles.rightContent} flex-row`}>
                            <div className={`${styles.perDayContainer} flex-row`}>
                                <p className={styles.dollar}>$</p>
                                <h1 className={styles.beforeDot}>{Math.trunc(7)}</h1>
                                <div className={`${styles.subtitleContainer} flex-column`}>
                                    <span className={styles.afterDot}>{99}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
            localStorage.getItem('paymentMethodType') === "stripe"
            ?  <button className={styles.buttonContainer} onClick={confirmAction} >
                             {textData.continueButton}
                          </button>
            :  <PayPalScriptProvider options={{ "client-id": paypalClientId, vault:true }}>
                                 <PayPalButtons
                                     style={{ layout: "horizontal", width: 185, height: 48, borderRadius: 8 }}
                                     createSubscription={(data, details) => paypalSubscribe(data, details)}
                                     onApprove={(data, details) => paypalOnApprove(data, details)}
                                     onClick={() => amplitude.getInstance().logEvent('button_onboarding_payment_paypal_tapped')}
                                 />
                             </PayPalScriptProvider>
            }

            <div className={`${styles.skipButton} flex-row`} onClick={skipStep}>
                <span className={styles.buttonText}>{textData.skipButton}</span>
                <img className={styles.skipIcon} loading="lazy" src="/images/icons/gray-arrow.svg" alt="" />
            </div>
        </div>
     </>
    )
}

export default RegistrationOffer;