import {useState, useEffect, useRef} from "react";

import styles from "./DownloadAppsBox.module.scss";

import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";

const DownloadAppsBox = (props) => {
    const { } = props;

    return (
        <>
            <div className={`${styles.mainContainer} flex-column`}>
                <div className={`${styles.headerBox} flex-row`}>
                    <div className={styles.leftOliveImageBox} >
                        <LazyImage
                            className={styles.imageBox}
                            srcWebp="/images/paywall/greenTheme/leftOlive.webp"
                            src="/images/paywall/greenTheme/leftOlive.png"
                            alt=""
                        />
                    </div>
                    <div className={`${styles.header} flex-column`}>
                        <div className={styles.title}>
                            {strings.paywall.greenTheme.downloadApps.title}
                        </div>
                        <div className={styles.subtitle}>
                            {strings.paywall.greenTheme.downloadApps.subtitle}
                        </div>
                    </div>
                    <div className={styles.rightOliveImageBox}>
                        <LazyImage
                            className={styles.imageBox}
                            srcWebp="/images/paywall/greenTheme/rightOlive.webp"
                            src="/images/paywall/greenTheme/rightOlive.png"
                            alt=""
                        />
                    </div>
                </div>

                <div className={`${styles.downloadLinksBox} flex-row`}>
                    <div className={styles.ratingImageBox}>
                        <LazyImage
                            className={styles.imageBox}
                            srcWebp="/images/paywall/greenTheme/rating.webp"
                            src="/images/paywall/greenTheme/rating.png"
                            alt=""
                        />
                    </div>
                    <div className={styles.appStoreImageBox}>
                        <LazyImage
                            className={styles.imageBox}
                            srcWebp="/images/paywall/greenTheme/appStore.webp"
                            src="/images/paywall/greenTheme/appStore.png"
                            alt=""
                        />
                    </div>
                    <div className={styles.googlePlayImageBox}>
                        <LazyImage
                            className={styles.imageBox}
                            srcWebp="/images/paywall/greenTheme/googlePlay.webp"
                            src="/images/paywall/greenTheme/googlePlay.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DownloadAppsBox;
