import styles from "./ResultsCard.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";

const ResultsCard = props => {
    const { card } = props;

    return (
        <div className={`${styles.cardContainer}`}>
            <div className={`${styles.imagesContainer} flex-between`}>
                <div className={styles.imageContainer}>
                    <LazyImage
                        srcWebp={card.before.imageWebp}
                        src={card.before.image}
                        alt=""
                    />
                    <p>{strings.paywall.feedbacks[card.id].before}</p>
                </div>
                <div className={styles.imageContainer}>
                    <LazyImage
                        srcWebp={card.after.imageWebp}
                        src={card.after.image}
                        alt=""
                    />
                    <p className={styles.afterText}>{strings.paywall.feedbacks[card.id].after}</p>
                </div>
            </div>
            <div className={`${styles.dataContainer} flex-between`}>
                <p>{strings.paywall.feedbacks[card.id].name}</p>
                <p className={styles.weightData}>{strings.paywall.feedbacks[card.id].weight}</p>
            </div>
            <p className={styles.feedback}>{strings.paywall.feedbacks[card.id].feedback}</p>
        </div>
    )
}   

export default ResultsCard;