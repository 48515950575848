import {useEffect, useState} from 'react';
import { useDispatch } from "react-redux";

import styles from "./PaywallTimer.module.scss";
import strings from "../../../assets/localization/strings";

const PaywallTimer = props => {
    const { innerRef, stopTimer, setStopTimer, activeRate, onlyTime } = props;
    let timer = [5, 0, 0]
    if(localStorage.getItem('timer')) {
        const storageTimer = JSON.parse(localStorage.getItem('timer'))
        timer = [storageTimer.minutes, storageTimer.seconds, storageTimer.milliseconds]
        localStorage.removeItem('timer');
    }
    const [[m, s, ms], setTime] = useState(timer);
    const dispatch = useDispatch();

    useEffect(() => {
        const timerID = setInterval(() => tick(), 33);
        dispatch({type: 'SELECT_PERIOD', payload: activeRate})
        return () => clearInterval(timerID);
    })

    const tick = () => {
        if(stopTimer) return;
        if (m === 0 && s === 0 && ms === 0) {
            setStopTimer(true);
        } else if (s === 0 && ms === 0) {
            setTime([m - 1, 59, 99]);
        } else if (ms === 0) {
            setTime([m, s - 1, 99]);
        } else {
            setTime([m, s, ms - 3]);
        }
    }

    return (
        <div className={`${styles.timerContainer} ${onlyTime ? styles.whiteTime : '' } flex-column`}>
            { !onlyTime && (<p>{strings.paywall.reserved}</p>)}
            <span ref={innerRef}>{`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}:${ms.toString().padStart(2, '0')}`}</span>
        </div>
    )
}

export default PaywallTimer;
