import styles from "./StartDiscount.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";

const StartDiscount = (props) => {
    const { style } = props;

    const containerClass = `${styles.startTrialBox} ${styles[`${style}Campaign`]}`

    return (
        <div className={containerClass}>
            <div className={styles.startTrialContainer}>
                <div className={styles.startTrial}>
                   <div className={styles.discountInfo}>
                    <h1>
                      {strings.paywall.discount.previous}
                      <span className={styles.redTextPrev}>50%</span>
                    </h1>
                    <h2>
                        {strings.paywall.discount.titleStart}
                        <br/>
                        <span className={styles.redText}>60% {strings.paywall.discount.discount}</span>
                     </h2>
                   </div>
                    <div className={styles.giftImage}>
                        <LazyImage
                            srcWebp="/images/gift_icon.webp"
                            src="/images/gift_icon.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
      )
  }

export default StartDiscount;