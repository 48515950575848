import { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

import "./Checkbox.scss";
import styles from "../mealPlanBonus/MealPlanBonus.module.scss";

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    position: 'relative',
    width: 20,
    height: 20,
    padding: 0,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#FFFFFF',
    '.Mui-focusVisible &': {
      outline: '1px solid #BBBBBB',
    },
}));
  
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%)',
    backgroundImage: 'linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%)',
    boxShadow: '0px 0px 2px rgba(225, 87, 104, 0.7)',
    '&:before': {
        position: 'absolute',
        top: '55%',
        left: '50%',
        width: 10,
        height: 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage:
        "url('/images/icons/check-icon.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 8px',
        content: '""',
    },
});

const YellowBpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#FDE007',
    backgroundImage: '#FDE007',
    boxShadow: 'unset',
    '&:before': {
        position: 'absolute',
        top: '55%',
        left: '50%',
        width: 10,
        height: 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage:
            "url('/images/icons/check-icon.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 8px',
        content: '""',
    },
});

const GreenBpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#AFD6D5',
    backgroundImage: '#AFD6D5',
    border: '0.5px solid #2D603F',
    boxShadow: 'unset',
    '&:before': {
        position: 'absolute',
        top: '55%',
        left: '50%',
        width: 10,
        height: 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage:
            "url('/images/icons/check-icon-black.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 8px',
        content: '""',
    },
});

const LightGreenBpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#57A97B',
    backgroundImage: '#57A97B',
    boxShadow: 'unset',
    '&:before': {
        position: 'absolute',
        top: '55%',
        left: '50%',
        width: 10,
        height: 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage:
            "url('/images/icons/check-icon.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 8px',
        content: '""',
    },
});

const PurpleBpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#7D8BF6',
    backgroundImage: '#7D8BF6',
    boxShadow: '0px 0px 2px rgba(202, 207, 247, 0.79)',
    '&:before': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 10,
        height: 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage:
            "url('/images/icons/check-icon.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 8px',
        content: '""',
    },
});

const checkedStyledIcon = (color) => {
    switch(color) {
        case "pink":
            return <BpCheckedIcon />
        case "yellow":
            return <YellowBpCheckedIcon />
        case "green":
            return <GreenBpCheckedIcon />
        case "lightGreen":
            return <LightGreenBpCheckedIcon />
        case "purple":
            return <PurpleBpCheckedIcon />
        default:
            return <BpCheckedIcon />
    }
}

const CustomCheckbox = props => {
    const { data, checkCard, color } = props;
    const [card, setCard] = useState(data)

    useEffect(() => {
        setCard(data)
    }, [data])

    const checkedCard = (e) => {
        setCard({...card, checked: e.target.checked});
        checkCard({...card, checked: e.target.checked});
    }

    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disabled={card.disabled || false}
            disableRipple
            checked={card.checked}
            checkedIcon={checkedStyledIcon(color)}
            icon={<BpIcon />}
            onChange={e => checkedCard(e)}
            // {...props}
        />
    )
}   

export default CustomCheckbox;