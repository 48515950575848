import styles from "./ContinueButton.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const ContinueButton = props => {
    const { text = strings.continue, theme, nextStep, disableButton, customStyles } = props;
    const buttonClassName = `${styles.buttonContainer} ${disableButton ? styles.disabledButton : ''}`
    const inlineStyles = { background: (disableButton ? theme?.backgroundDisabled : theme?.background), ...customStyles}

    return (
        <button
            ref={(node) => {
                if (node && theme?.boxShadow) {
                    node.style.setProperty("box-shadow", theme.boxShadow, "important");
                }
            }}
            className={buttonClassName}
            style={inlineStyles}
            onClick={nextStep}
            disabled={!!disableButton}
        >
            {text}
            <img loading="lazy" src="/images/icons/white-arrow.svg" alt="" />
        </button>
    )
}   

export default ContinueButton;
