import { useState } from "react";

import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

import styles from "./CheckboxImageCard.module.scss";
import { useEffect } from "react";
import LazyImage from "../lazyImage/LazyImage";

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    position: 'relative',
    width: 20,
    height: 20,
    padding: 0,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#FFFFFF',
    '.Mui-focusVisible &': {
      outline: '1px solid #BBBBBB',
    },
}));
  
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%)',
    backgroundImage: 'linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%)',
    boxShadow: '0px 0px 2px rgba(225, 87, 104, 0.7)',
    '&:before': {
        position: 'absolute',
        top: '55%',
        left: '50%',
        width: 10,
        height: 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage:
        "url('/images/icons/check-icon.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 8px',
        content: '""',
    },
});

const CheckboxImageCard = props => {
    const { data, selectCard } = props;
    const [card, setCard] = useState(data);

    useEffect(() => {
        setCard(data)
    }, [data])

    const checkCard = value => {
        setCard({...card, checked: value});
        selectCard({...card, checked: value});
    };

    const clickCard = () => {
        setCard({...card, checked: !card.checked});
        selectCard({...card, checked: !card.checked});
    }

    return (
        <div className={`${styles.cardContainer} ${card?.checked ? styles.activeCard : ''}`} onClick={clickCard}>
            <div className={styles.cardImage}>
                <LazyImage
                    srcWebp={data.imageWebp}
                    src={data.image}
                    alt=""
                />
            </div>
            <div className={`${styles.titleContainer} flex-row`}>
                <Checkbox
                    sx={{
                        '&:hover': { bgcolor: 'transparent' },
                    }}
                    disableRipple
                    checked={card.checked}
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={e => checkCard(e.target.value)}
                />
                <p className={styles.cardText}>{data.title}</p>
            </div>
        </div>
    )
}   

export default CheckboxImageCard;