import styles from "./RateCard.module.scss";

import CustomCheckbox from "../checkbox/Checkbox";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";

const RateCardHeader = props => {
    const { isCampaign, isPopular, isActive, pageInfo, discount } = props;
    const imageUrl = isActive ? pageInfo.cardImage : pageInfo.cardInactiveImage
    const imageUrlWebp = isActive ? pageInfo.cardImageWebp : pageInfo.cardInactiveImageWebp
    const textBox = strings.paywall.cardHeader
    const isTrial = pageInfo.saleID === "trial"
    return (
        <div className={`${styles.header} flex-row`}>
            {
                isCampaign ? (
                    isPopular ? (
                        <>
                            <div className={`${styles.saleHeader} flex-row`}>
                                { isTrial
                                    ? (<span style={{"fontSize": textBox.trial.fontSize}}>{textBox.trial.text}</span>)
                                    : (<span style={{"fontSize": textBox.save.fontSize}}>{textBox.save.text}{discount}%</span>)
                                }
                                <LazyImage
                                    className={styles.imageSaleStar}
                                    srcWebp={imageUrlWebp}
                                    src={imageUrl}
                                    alt=""
                                />
                                <span style={{"fontSize": textBox.mostPopular.fontSize}}>{textBox.mostPopular.text}</span>
                                <LazyImage
                                    className={styles.imageSaleStar}
                                    srcWebp={imageUrlWebp}
                                    src={imageUrl}
                                    alt=""
                                />
                                { isTrial
                                    ? (<span style={{"fontSize": textBox.trial.fontSize}}>{textBox.trial.text}</span>)
                                    : (<span style={{"fontSize": textBox.save.fontSize}}>{textBox.save.text}{discount}%</span>)
                                }

                            </div>
                        </>
                    ) : (
                           <>
                               { isTrial
                                   ? (<span style={{"fontSize": textBox.trial.fontSize}}>{textBox.trial.text}</span>)
                                   : (<span style={{"fontSize": textBox.save.fontSize}}>{textBox.save.text}{discount}%</span>)
                               }
                           </>
                        )
                ) : (
                    <>
                        <LazyImage
                            srcWebp="/images/icons/like.webp"
                            src="/images/icons/like.png"
                            alt=""
                        />
                        <p>Most popular</p>
                    </>
                )
            }
        </div>
    )
}

const RateCard = props => {
    const { card, setActiveCard, activeCard, pageInfo, discount, style } = props;
    const selectCard = card => {
        setActiveCard(card)
    }
    let isActive = activeCard?.id === card?.id;
    const greenThemeCase = style === "lightGreen"
    const blackFridayCase = style === "bf"
    const newYearCase = style === "ny"
    const isCampaign = !!style
    const campaignStyle = isCampaign ? `${styles.campaignCase} ${styles[`${style}CampaignCase`]}` : ""
    const activeCampaignStyle = isCampaign ? styles[`${style}ActivePopular`] : ""
    let isActiveCampaignPopular = isActive && isCampaign && card?.id === 1;

    const checkboxColor = blackFridayCase && isActiveCampaignPopular ? 'yellow' :
        newYearCase && isActiveCampaignPopular ? 'green' :
        greenThemeCase ? 'lightGreen' : 'pink'

    const withHeader = card?.id === 1 || isCampaign
    const cardContainerClass = `${isActive ? styles.activeCard : ''} ${withHeader ? styles.popular : ''} ${campaignStyle} ${isActiveCampaignPopular ? activeCampaignStyle : ''}`

    return (
        <div
            className={`${styles.cardContainer} ${cardContainerClass} flex-column`}
            onClick={() => setActiveCard({...card, checked: !card.checked})}
        >
            { withHeader && (
                <RateCardHeader
                    isActive={isActive}
                    isPopular={card?.id === 1}
                    pageInfo={pageInfo}
                    isCampaign={isCampaign}
                    discount={discount}
                />
            )}
            <div className={`${styles.wrapper} flex-between`}>
                <div className={`flex-row`}>
                    <CustomCheckbox data={card} color={checkboxColor} checkCard={selectCard}/>
                    <div className={`${styles.priceContainer} flex-column`}>
                        <h3 className={styles.title}>{strings.paywall.products[card.title]}</h3>
                        <div className={`flex-row`}>
                            <p className={styles.oldPrice}>${card.oldTotal} <span></span></p>
                            <p className={styles.newPrice}>${card.total}</p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.rightContent} flex-row`}>
                    <p className={`${styles.oldPricePerDay} ${styles.oldPrice}`}>${card.oldPerDay.toFixed(2)} <span></span></p>
                    <div className={`${styles.perDayContainer} flex-row`}>
                        <p className={styles.dollar}>$</p>
                        <h1 className={styles.beforeDot}>{Math.trunc(card.newPerDay)}</h1>
                        <div className={`${styles.subtitleContainer} flex-column`}>
                            <span className={styles.afterDot}>{Math.floor ((card.newPerDay - Math.floor (card.newPerDay)) * 100)}</span>
                            <p className={styles.subtitle}>{strings.paywall.cardHeader.perDay}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default RateCard;