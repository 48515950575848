
import styles from "./ImageCard.module.scss";
import LazyImage from "../lazyImage/LazyImage";

const ImageCard = props => {
    const { data, selectCard } = props;

    return (
        <div className={`${styles.cardContainer}`} onClick={() => selectCard(data)}>
            <div className={styles.cardImage}>
                <LazyImage
                    srcWebp={data.imageWebp}
                    src={data.image}
                    alt=""
                    onClick={() => selectCard(data)}
                />
            </div>
            <p className={styles.cardText}>{data.title}</p>
        </div>
    )
}   

export default ImageCard;