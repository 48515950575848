import styles from "./VideoFeedbacks.module.scss";
import CircleVideoPlayer from "../circleVideoPlayer/CircleVideoPlayer";
import strings from "../../../assets/localization/strings";
import {useEffect, useRef, useState} from "react";

const mutedFeedbacks = {0: true, 1: true, 2: true}

const VideoFeedbacks = (props) => {
    const { feedbacks } = props;
    const [mutedVideos, setMutedVideos] = useState(mutedFeedbacks);

    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            const screenHeight = window.innerHeight;
            const elementPosition = containerRef.current.offsetTop;
            if(scrollTop + screenHeight < elementPosition || scrollTop > elementPosition + 300) {
                setMutedVideos(mutedFeedbacks)
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMuteAll = (key, value) => {
        setMutedVideos({...mutedFeedbacks, [key]: value })
    }

    return (
        <div ref={containerRef} className={`${styles.mainContainer} flex-column`} >
            <h2 className={styles.title}>
                {strings.paywall.usersMeetGoals}
            </h2>
            <div className={`${styles.feedbacksContainer}`}>
                <div className={`${styles.scrollableArea} flex-row`}>
                    {
                        feedbacks.map(feedback =>
                            <div key={feedback.id} className={styles.feedbackContainer}>
                                <div className={styles.feedbackVideo}>
                                    <CircleVideoPlayer
                                        id={feedback.id}
                                        src={feedback.src}
                                        srcWebm={feedback.srcWebm}
                                        poster={feedback.poster}
                                        muteVideo={mutedVideos[feedback.id]}
                                        handleMuteAll={handleMuteAll} />
                                </div>
                                <div className={styles.feedbackName} >
                                    {feedback.name}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default VideoFeedbacks
