import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./Benefits.module.scss";
import LazyImage from "../../UI/lazyImage/LazyImage";
import LabelWithCheckbox from "../../UI/labelWithCheckbox/LabelWithCheckbox";

const Benefits = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.benefits;
    const textInfo = strings.plan.benefits;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_benefits_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>{textInfo.title}</h1>
            <div className={`${styles.imageBanner} flex-column`}>
                <LazyImage
                    srcWebp="/images/plan/benefits-image.webp"
                    src="/images/plan/benefits-image.png"
                    alt=""
                />
            </div>
            <div className={styles.pointsBox} >
                { pageInfo.cards.map(card =>
                    (<LabelWithCheckbox
                        key={card.id}
                        theme={themeData.checkboxCardColor}
                        labelText={textInfo.labels[card.localID]}
                    />)
                )}
            </div>
            <ContinueButton
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default Benefits;