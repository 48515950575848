import { useEffect, useState } from "react";
import { useFeatureValue } from "@growthbook/growthbook-react";
import { useNavigate } from "react-router";

import amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import moment from 'moment/moment';
import sha256 from 'crypto-js/sha256';

import ImageCard from "../../components/UI/imageCard/ImageCard";
import useJsonParse from "../../hooks/useJsonParse";

import styles from "./AgeGroup.module.scss";

const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';
const netToken = 'EAACZBgaOc86EBOZBtOPqwuocghfnngNyTZA20iQcbMGWXTM8lPutMXk6gsIw3csDiZAqSNPCHejHLSHg3TJkea6ZCVIwJJaRcEcV8vXKxk6TYGuDiWBfFaMWAfokNL1mrhzoE01qhaTE2GH42aDrdMKpdwnoCsFz6Mvd9NB0neyF9Oz8HxVIux47RYtW7OWvdWAZDZD';
const tiktokApiToken = 'e9cb49ddaa71504c0628507456187bbfb9368637';

const AgeGroup = (props) => {
    const { data } = props;
    const pageInfo = data.settings.ageGroup
    const [cards, setCards] = useState(useJsonParse(pageInfo.cards));
    const value = useFeatureValue("age-goal", "fallback");
    const [activeCard, setActiveCard] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('discountStatus');
        const eventId = `viewcontent.${getCookie('id')}`;
        ReactPixel.track('ViewContent', {eventID: eventId});
        TiktokPixel.track('ViewContent', {event_id: eventId});
        fetch(`https://graph.facebook.com/v10.0/${(data.domain.includes('net') ? '1085241235883188' : '239948538703054')}/events?access_token=${data.domain.includes('net')? netToken: token}`, {
            method: "POST",
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: [
                  {
                    event_name: "ViewContent",
                    event_time: moment().unix(),
                    action_source: "website",
                    event_id: eventId,
                    user_data: {
                        client_user_agent: navigator.userAgent,
                        external_id: sha256(getCookie('id')).toString()
                    }
                  }
                ]
            })
        })

        const payload = {

                                                     "pixel_code": "CP2RST3C77UF83EV1CUG",
                                                     "event_id": eventId,
                                                     "event": "ViewContent",
                                                     "timestamp": new Date(Date.now()),
                                                     "context": {
                                                           user: {
                                                              external_id: sha256(getCookie('id')).toString()
                                                           },
                                                           user_agent: navigator.userAgent,
                                                     }
                                                    };

                                                   const ttc = document.cookie.split(';').filter(c => c.includes('tiktok_click_id=')).map(c => c.split('tiktok_click_id=')[1]).join();
                                                   const ttp = document.cookie.split(';').filter(c => c.includes('_ttp=')).map(c => c.split('_ttp=')[1]).join();

                                                   if(ttc) {
                                                     payload.context.user.ttclid = ttc;
                                                   }

                                                   if(ttp) {
                                                     payload.context.user.ttp = ttp;
                                                   }

                                                   fetch('https://smvcqm8fu5.execute-api.us-east-1.amazonaws.com/dev/tiktok_pixel_event', {
                                                     method: "POST",
                                                     headers: {
                                                       'Accept': 'application/json',
                                                       'Content-Type': 'application/json'
                                                     },
                                                     body: JSON.stringify(payload)
                                                   })
    }, [])

    useEffect(() => {
        if(value === 'a' || value === 'b') {
            setCards(pageInfo.cards)
            amplitude.getInstance().logEvent('screen_onboarding_age_group_appeared', { version: value});
        }
    }, [value])

    const selectCard = card => {
        setActiveCard(card);
        navigate('steps');
        amplitude.getInstance().logEvent('button_onboarding_age_group_tapped', { answer: card.title});
    }

    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
          "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    const campaign = localStorage.getItem('campaign');
    const variantA = campaign === 'a';
    const variantB = campaign === 'b';

    return (
        <div className={`${styles.ageGroupContainer} flex-column`}>
            <img loading="lazy" src="/images/Logo.svg" alt="" />
            <div className={`${styles.starsContainer} flex-row`}>
                <img loading="lazy" className={styles.starsImage} src="/images/stars.svg" alt="" />
                <h2 className={styles.rating}>4.5</h2>
            </div>
            { variantA ? (
                <>
                   <p className={styles.subtitleA}><b>1M+</b> downloads</p>
                   <p className={styles.downloadsTextA}>Choose your age and install the Everdance app</p>
                </>
            ) : (
                <>
                    <p className={styles.downloadsText}>
                        { variantB ? "Your personal dancing plan" : "Your dancing plan for weight-loss" }
                    </p>
                    <p className={styles.subtitle}>According to your <b>age</b></p>
                </>
            )}
            <div className={styles.gridContainer}>
                {cards?.map(card => (
                    <ImageCard key={card.id} data={card} selectCard={selectCard} />
                ))}
            </div>
        </div>
    )
}   

export default AgeGroup;