import {useEffect, useState} from "react";
import amplitude from 'amplitude-js';

import styles from "./CreatingPlan.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import CoverflowSwiper from "../../UI/coverflowSwiper/CoverflowSwiper";

const CreatingPlan = (props) => {
    const { data, nextStep } = props;
    const textData = strings.plan.creatingPlan
    const messages = textData.messages
    const [tick, setTick] = useState(0);
    const [timer, setTimer] = useState();
    const [currentSlide, setCurrentSlide] = useState(6);

    useEffect(() => {setTimer(setInterval(() => setTick(prev => prev + 1), 60));
        amplitude.getInstance().logEvent('screen_onboarding_creating_plan_appeared');
        return () => {
            setTick(0);
            clearInterval(timer);
        }
    }, [])

    useEffect(() => {
        if(tick === 100) {
            clearInterval(timer);
            setTimeout(() => {
               nextStep();
            }, 3000)
        }
    }, [tick])

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <div className={styles.creatingPlanContainer}>
                <div className={`${styles.loadingContainer} flex-row`}>
                    <div className={styles.circleContainer}>
                        <p className={styles.circlePercentage}>{tick}%</p>
                        <svg>
                            <defs>
                                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" stopColor="#7D8BF6" />
                                    <stop offset="100%" stopColor="#7D8BF6" />
                                </linearGradient>
                            </defs>
                            <circle cx="19" cy="19" r="19" stroke="#7D8BF6" strokeDashoffset={`calc(118px - (118px * ${tick}) / 100)`}></circle>
                        </svg>
                    </div>
                    <div className={styles.subtitle}>
                        {textData.subtitle}
                    </div>
                </div>
            </div>
            <h1 className={styles.title}>{textData.title}</h1>
            <div className={styles.swiperArea}>
                <CoverflowSwiper data={data} changeSlide={setCurrentSlide} />
            </div>
            <div className={styles.descriptionArea}>
                {
                    messages.map((message, i)=> (
                        <div key={i} className={`${styles.title} ${currentSlide === i+1 || currentSlide === (i+4) ? styles.active : '' }`}>
                            {message.firstTitle}
                            <span>{message.secondTitle}</span>
                            {message.thirdTitle}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CreatingPlan;
