import CoverflowSwiper from "../coverflowSwiper/CoverflowSwiper";
import styles from "./stayOnTopBox.module.scss";
import strings from "../../../assets/localization/strings";

const stayOnTopBox = (props) => {
    const { data } = props;
    return (
        <div className={`${styles.mainContainer} flex-column`} >
            <h2 className={styles.title}>
                {strings.paywall.greenTheme.stayOnTop.titleOne}
                <span>{strings.paywall.greenTheme.stayOnTop.titleTwo}</span>
            </h2>
            <div className={styles.swiperArea}>
                <CoverflowSwiper data={data} />
            </div>

        </div>
    )
}

export default stayOnTopBox
